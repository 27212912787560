<template>
  <div class="page-body">
    <div class="main-login">
      <div class="header">
        <p class="header-title">管理系统</p>
      </div>
      <a-form class="login-form" layout="horizontal" ref="formRef" :model="formState" :rules="formRules"
              @finish="onSubmit">
        <a-form-item name="username">
          <a-input class="login-input" size="large" v-model:value="formState.username" placeholder="请输入账号" autocomplete>
            <template #prefix>
              <UserOutlined style="color: rgba(0, 0, 0, 0.25)" />
            </template>
          </a-input>
        </a-form-item>
        <a-form-item name="password">
          <a-input class="login-input" size="large" v-model:value="formState.password" type="password"
                   placeholder="请输入密码" @keyup.enter="onSubmit" autocomplete>
            <template #prefix>
              <LockOutlined style="color: rgba(0, 0, 0, 0.25)" />
            </template>
          </a-input>
        </a-form-item>
        <!--        <a-form-item name="verify">-->
        <!--          <SliderVerify v-model:isPassing="formState.verify" />-->
        <!--        </a-form-item>-->
        <a-form-item>
          <a-input class="login-input" size="large" v-model:value="formState.captcha" type="text" placeholder="请输入验证码" style="width: 150px;">
          </a-input>
          <img :src="verify" alt=""  @click="clickCaptcha" style="width: 122px;height: 35px;margin-left: 2%;" >
          <a-input type="hidden" v-model:value="formState.captcha_id"></a-input>
        </a-form-item>
        <a-form-item>
          <a-button class="login-button" :loading="loading" type="primary" html-type="submit">
            登录
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import { UserOutlined, LockOutlined } from "@ant-design/icons-vue";
import { ref, reactive ,onMounted} from "vue";
import { useStore } from "vuex";
import * as Api from "@/store/api";
// import SliderVerify from "@/components/SliderVerify";
// import { useRouter } from "vue-router";
import { message } from "ant-design-vue";

export default {
  name: "Login",
  components: {
    UserOutlined,
    LockOutlined,
    // SliderVerify
  },
  setup() {
    // const router = useRouter();
    const store = useStore();

    const formState = reactive({
      username: "",
      password: "",
      verify: false,
      captcha:"",
      captcha_id:"",
    });

    // let sliderVerify = async (_rule, value) => {
    //   if (value == '') {
    //     return Promise.reject('请滑动滑块验证');
    //   }
    //   if (value === false) {
    //     return Promise.reject('请滑动滑块验证');
    //   } else {
    //     return Promise.resolve();
    //   }
    // };

    const formRules = {
      username: [{ required: true, message: "请输入账户", trigger: "blur" }],
      password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      captcha:  [{ required: true, message: "请输入验证码", trigger: "blur" }],
      // verify: [{ validator: sliderVerify, trigger: "change" }],
    };



    const formRef = ref();
    const verify = ref();
    const loading = ref(false);

    const captcha = () => {
      Api.captcha().then((res) => {
        verify.value = res.data.img;
        formState.captcha_id = res.data.id;
      });
    };
    const clickCaptcha = () =>{
      captcha();
    };
    onMounted(() => {
      captcha();
    });
    // 登录操作
    const onSubmit = () => {
      loading.value = true;
      formRef.value.validate().then(() => {
        store
            .dispatch("user/login", formState)
            .then(() => {
              loading.value = false;
              // location.reload();
            })
            .catch((error) => {
              loading.value = false;
              message.error(error);
            });
      });
    };

    return {
      formRules,
      formState,
      onSubmit,
      formRef,
      loading,verify,clickCaptcha
    };
  },
};
</script>
<style lang="less" scoped>
.page-body {
  width: 100vw;
  height: 100vh;
  position: relative;
  text-align: center;
  // background-image: url("~@/assets/img/login-bg.jpg");
  background: linear-gradient(90deg, #7f7fd5, #86a8e7, #91eae4);

  &::before {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    content: "";
  }

  .main-login {
    display: inline-block;
    vertical-align: middle;
    min-width: 260px;
    width: 400px;
    margin: -80px auto 0 auto;
    padding: 70px 60px;
    text-align: left;
    // box-shadow: 0 0 2px 4px #f1f1f1;
    background-color: #fff;

    .header {
      vertical-align: top;
      border-style: none;
      margin-bottom: 20px;
      text-align: center;

      .logo {
        height: 50px;
        vertical-align: top;
        border-style: none;
        margin-bottom: 20px;

        .login-login {
          border-radius: 50%;
        }
      }

      .header-title {
        font-size: 18px;
        color: #6c7293;
        font-family: Microsoft YaHei, Avenir, Helvetica Neue, Arial, Helvetica,
        sans-serif;
        position: relative;
        margin: 0;
        text-align: center;
      }
    }

    .login-form {
      &:deep(.login-input) {
        border: none;
        background: rgba(235, 237, 242, 0.4);
        border-radius: 5px;
        font-size: 14px;
      }

      &:deep(.login-input .ant-input) {
        border: none;
        background: none;
        border-radius: 5px;
        font-size: 14px;
      }

      .login-error {
        margin-bottom: 20px;
      }

      .login-button {
        width: 100%;
      }
    }
  }
}
</style>